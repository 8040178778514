import gql from "graphql-tag";

export const PENDING_TRAINING_REQUESTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalTrainingRequests {
        id
        note
        approved
        nextApprover
        trainingRequest {
          id
          detail
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          training {
            id
            name
            color
          }
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          abolishDateTime
          abolishReason
          requester {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          trainingRequestStatus
          requestDateTime
          trainingRequestProcesses {
            id
            approver {
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
            approved
            note
            eventDateTime
            nextApprover
          }
        }
      }
    }
  }
`;
